import { AuthService } from './../../shared/services/auth.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ServiceAppointmentState } from '../../store/service-appointment/service-appointment.reducer';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../../store/service-appointment/service-appointment.selectors';
import { Observable, Subscription } from 'rxjs';
import { AppointmentType, Department, IAvailability, IAvailabilityOptions, ITransportOption } from '@signal/asp-data-commons';
import { LoadAvailability } from '../../store/service-appointment/service-appointment.actions';
import { filter, take } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { AnalyticsService } from './../../shared/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import {serviceSettings} from '../../store/dealer/dealer.selectors';
import { DealerState } from '../../store/dealer/dealer.reducer';

@Component({
  selector: 'app-appointment-time',
  templateUrl: './appointment-time.component.html',
  styleUrls: ['./appointment-time.component.scss']
})
export class AppointmentTimeComponent implements OnInit, OnDestroy {
  @Input() dealerDetails;
  @Input() stepIndex = 2;
  @Input() vin: string;
  @Input() appointmentId: string;
  signedIn: boolean;
  isValidRemoteZip = false;
  currentDate = new Date();
  department:string = Department.SERVICE;
  step: UntypedFormGroup;
  private readonly availability: UntypedFormControl;
  availabilityOptions$: Observable<IAvailabilityOptions>;
  availabilityOptionsLoading$: Observable<boolean>;
  availability$: Observable<IAvailability>;
  availabilityLoading$: Observable<boolean>;
  transportOptions$: Observable<ITransportOption[]>;
  remoteZipcodes$: Observable<{ pmaZipCodes: string[], isRemote: boolean }>;
  editModeSub: Subscription;
  lastRemoteZipCode: string;
  languageId='';
  isEditMode=false;
  apptAdvisor= '';
  apptType= AppointmentType.SERVICE;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly dealerState:Store<DealerState>,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService,
    private readonly translate: TranslateService
  ) {
    this.authService.isSignedIn.subscribe(loggedIn => {
      this.signedIn = loggedIn;
    });
  }

  ngOnDestroy(): void {
    this.editModeSub.unsubscribe();
  }

  ngOnInit(): void {
    this.ready();
  }

  ready() {
    this.step = this.formBuilder.group({
      transport: ['', Validators.required],
      remoteZipCode: [''],
      availability: this.availability,
      timeSlotValidator: [false, Validators.required]
    });

    this.availabilityOptions$ = this.serviceAppointmentState.select(fromSelectors.selectAvailabilityOptionsData);
    this.availabilityOptionsLoading$ = this.serviceAppointmentState.select(fromSelectors.selectAvailabilityOptionsLoading);
    this.availability$ = this.serviceAppointmentState.select(fromSelectors.selectAvailabilityData);
    this.availabilityLoading$ = this.serviceAppointmentState.select(fromSelectors.selectAvailabilityLoading);
    this.transportOptions$ = this.serviceAppointmentState.select(fromSelectors.selectTransportOptions);
    this.remoteZipcodes$ = this.serviceAppointmentState.select(fromSelectors.selectPMAZipCodesAndRemoteIndicator);
    this.serviceAppointmentState.select(fromSelectors.selectAppointmentForm).subscribe((data:any) => {
      this.languageId=data?.availabilityForm?.availability?.languageId;
      this.isEditMode=data?.isEditMode;
      this.apptAdvisor = data?.availabilityForm?.availability?.advisorId;
    });
    this.step.controls.remoteZipCode.setValidators(this.zipValidator());
    this.dealerState.select(serviceSettings).subscribe(settings => {
      if(settings)
      this.department = settings.department
    });
   
    this.editModeSub = this.step.controls.remoteZipCode.valueChanges.subscribe(zip => {
      if (this.lastRemoteZipCode !== zip) {
        this.lastRemoteZipCode = zip;
        this.checkZipCodes(zip);
      }

    });
  }

  onMonthChange($event: DateTime) {
    this.serviceAppointmentState.select(fromSelectors.selectAvailabilityFormAdvisorId)
      .pipe(take(1))
      .subscribe((advisorId: string) => {
        const date = $event.toFormat('yyyy-MM-dd');
          this.serviceAppointmentState.dispatch(new LoadAvailability({ date, advisorId, transport: this.step.value.transport, vin: this.vin, appointmentId: this.appointmentId }));
      });
  }

  zipValidator(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors => {
      if (this.step.value.transport === 'REMOTE' && !this.isValidRemoteZip) {
        return { remoteZipCode: this.translate.instant('appointmentInformation.invalidRemotezipcode') };
      } else {
        return null;
      }
    };
  }

  zipTracker(input) {
    let zipTrackingData = {
      content_section: 'appointment tab',
      link_module: 'transportation',
      link_text: 'zipcode',
      link_input_field: input
    }
    if (this.isValidRemoteZip) {
      this.analyticsService.dealerData = { ...this.analyticsService.dealerData, isRemote: true };
      this.analyticsService.trackLink(zipTrackingData, true);
    } else {
      this.analyticsService.userInputError = 'location not supported for remote appointment';
      this.analyticsService.trackLink({
        ...zipTrackingData,
        ...this.analyticsService.userInputErrorBlock
      }, true);
    }
  }

  checkZipCodes(value) {
    this.step.controls.remoteZipCode.clearValidators();

    this.remoteZipcodes$.pipe(
      filter(zip => !!zip.pmaZipCodes?.length),
      take(1)).subscribe(zip => {
        if (zip.isRemote) {
          this.isValidRemoteZip = zip.pmaZipCodes.findIndex(x => x === value) !== -1;
          this.step.controls.remoteZipCode.setValidators(this.zipValidator());
          this.step.controls.remoteZipCode.updateValueAndValidity();
        }
      });
  }
  private getEndOfMonth(date: string) {
    const parseDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
    const endOfMonth = DateTime.fromFormat(`${parseDate.toFormat('yyyy-MM')}-01`, 'yyyy-MM-dd').plus({ month: 1, day: -1 });
    return endOfMonth;
  }
  setValidatorsForTimeSlot(event) {
    if (event) {
      this.step.controls.timeSlotValidator.setErrors({ error: 'Invalid timeslot' });
      this.step.controls.timeSlotValidator.setValidators(Validators.required);
    } else {
      this.step.controls.timeSlotValidator.clearValidators();
      this.step.controls.timeSlotValidator.updateValueAndValidity();
    }
  }
}
