<div class='customer-header' *ngIf="showContactInfo">
    <div class='header' >{{ 'testDrive.howCanWeReachYou?' | translate }}</div>
    <div class='login'>
        <div *ngIf='!signedIn'>{{ 'testDrive.LogInWithYourMyToyotaOrSmartPathAccount' | translate }}
            <span (click)='login()'>{{ 'welcome.logIn' | translate }}</span>
        </div>
        <div *ngIf='!signedIn'>{{ 'testDrive.donTHaveAMyToyotaOrSmartPathAccount' | translate }}
            <span>
                <a class="custom-css-create-account" target="blank" href="{{registerUrl}}" (click)="signUp()">
                    {{ 'welcome.createAccount'| translate}}
                </a>
            </span>
        </div>
    </div>
</div> 
<form [formGroup]='contactEmailInfo' class="appointment">
            <div [ngClass]="showContactInfo ? 'customer-dealer-details' : 'customer-details'">
                <div class="customer-form">
                    <mat-form-field appearance="outline" class='first-name'>
                    <mat-label>{{ 'appointmentInformation.firstName' | translate }}</mat-label>
                    <input matInput formControlName='firstName' required>
                    <mat-error *ngIf="f.firstName.invalid">{{ 'appointmentInformation.pleaseEnterYourFirstName' | translate }}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class='last-name'>
                    <mat-label>{{ 'appointmentInformation.lastName' | translate }}</mat-label>
                    <input matInput formControlName='lastName' required>
                    <mat-error *ngIf="f.lastName.invalid">{{ 'appointmentInformation.pleaseEnterYourLastName' | translate }}</mat-error>
                </mat-form-field>
                <div class="msg" *ngIf="!required">
                    <label style="color:black;font-size: medium;">{{ 'testDrive.emailOrPhone' | translate}}</label>            
                </div> 
                <mat-form-field appearance="outline" class='contact-number'>
                  <mat-label>{{ 'appointmentInformation.contactNumber' | translate }}</mat-label>
                  <ngx-mat-intl-tel-input [onlyCountries]="['us', 'mx', 'ca']" 
                  [preferredCountries]="[preferredCountries]" 
                  [enablePlaceholder]="true" 
                  [enableSearch]="false" 
                  format="national" formControlName="phoneNumber" [required]="required"></ngx-mat-intl-tel-input>
                    <mat-error *ngIf="f.phoneNumber.invalid">{{ 'appointmentInformation.pleaseEnterValidMobileNumber' | translate }}</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class='mail-id'>
                    <mat-label>{{ 'appointmentInformation.email' | translate }}</mat-label>
                    <input matInput formControlName='emailAddress'[required]="required">
                    <mat-error *ngIf="f.emailAddress.invalid">{{ 'appointmentInformation.pleaseEnterAValidEmail' | translate }}</mat-error>
                </mat-form-field>
                <div class="email" *ngIf="!required">
                    <div style="margin-top: 4px;">
                        <app-icon name="ic-20-feedback-alert-error.svg"></app-icon>
                    </div>
                    <label style="color:black;font-weight: bold;font-size: medium;">{{ 'testDrive.emailNotification' | translate}}</label>
                </div>
                </div>
                <div class='dont-forget'>
                    <div *ngIf="(amenities$ | async)?.length > 0" class='please-note'>
                        <div class='header'>{{ 'appointmentInformation.amenities' | translate }}</div>
                        <div class='content'>
                            <ol class="pl-4">
                                <li *ngFor="let amenity of amenities$ | async">{{amenity}}</li>
                            </ol>
                        </div>
                    </div>
                    <div class='contact-us' *ngIf="showContactInfo">
                        <div class='header'>{{ 'appointmentInformation.contactUs' | translate }}</div>
                        <div class='content'>
                            <div>{{ dealerName$ | async }}</div>
                            <div>{{ dealerAddress$ | async }}</div>
                            <div>
                                {{ ('appointmentInformation.openFrom' | translate) + (hoursOfOperation$ | async) + ' • ' + ((dealerPhone$ | async) | phone) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</form>
