import { Store } from '@ngrx/store';
import * as fromAppStore from '../../../modules/store/app.store';
import { DealerState } from '../../../modules/store/dealer/dealer.reducer';
import { filter, take } from 'rxjs/operators';
import * as fromDealerSelectors from '../../../modules/store/dealer/dealer.selectors';
import * as fromDealerActions from '../../../modules/store/dealer/dealer.actions';
import { languageCd, setCountryCd } from '../../../modules/shared/services/util.service';

export function initApp(store: Store<fromAppStore.AppState>,
    dealerState: Store<DealerState>) {
    return () => new Promise((resolve, reject) => {
        store.dispatch(new fromAppStore.StartAppInitializer());
        store.dispatch(new fromDealerActions.LoadFetchapikey('consumer'));
         const checkApiKey = setInterval(() => {
             const consumerApiKey = sessionStorage.getItem('consumer');
             if (consumerApiKey) {
                 clearInterval(checkApiKey);
                 dealerState.dispatch(new fromDealerActions.LoadDealerDetails());
                 dealerState.select(fromDealerSelectors.dealerDetailsData).pipe(
                     filter(dealer => dealer !== undefined ),
                     take(1)
                 ).
                 subscribe((dealer) => {
                     if (dealer !== null && dealer !== undefined && dealer.isConfigured && dealer.isConsumerPortalEnabled ) {
                         setCountryCd(dealer.address.country);
                         store.dispatch(new fromAppStore.FinishAppInitializer());
                         resolve(true);
                     }
                     else {
                         const languageCode = languageCd || (window as any).ASPHub?.languageCd;
                         if(languageCode === 'es'){
                             reject('Distribuidor no válido');
                             alert('Toyota Appointment Scheduling Portal no está configurado para este concesionario. Póngase en contacto con el concesionario para obtener más información.');
                         }else{
                             reject('Invalid Dealer');
                             alert('Toyota Appointment Scheduling Portal is not configured for this dealership. Please contact dealership for more information.');
                         }
                     }
                 });
             }
         }, 500);
    });
}
