import {Action} from '@ngrx/store';
import {IDealerInfo} from '@signal/asp-data-commons';
import { Settings } from './../../shared/models/settings/settings.model';
import { StateDetails } from './../../shared/models/state';

export enum DealerTypes {
  LOAD_DEALER_DETAILS = '[General Settings API] fetch sales and service settings from dealer Admin',
  LOAD_DEALER_DETAILS_SUCCESS = '[General Settings API] fetch sales and service settings from dealer Admin success',
  LOAD_DEALER_DETAILS_FAILURE = '[General Settings API] fetch sales and service settings from dealer Admin failure',
  LOAD_SETTINGS = '[General Settings API] fetch service settings from dealer Admin',
  LOAD_SETTINGS_SUCCESS = '[General Settings API] fetch service settings from dealer Admin success',
  LOAD_SETTINGS_FAILURE = '[General Settings API] fetch service settings from dealer Admin failure',
  LOAD_STATES = '[General Settings API] fetch States',
  LOAD_STATES_SUCCESS = '[General Settings API] fetch States success',
  LOAD_STATES_FAILURE = '[General Settings API] fetch States failure',
  LOAD_CITIES = '[General Settings API] fetch cities',
  LOAD_CITIES_SUCCESS = '[General Settings API] fetch cities success',
  LOAD_CITIES_FAILURE = '[General Settings API] fetch cities failure',
  LOAD_FETCH_API_KEY = '[FETCH_API_KEY] fetch',
  LOAD_FETCH_API_KEY_SUCCESS = '[FETCH_API_KEY] fetch success',
  LOAD_FETCH_API_KEY_FAILURE = '[FETCH_API_KEY] fetch failure'
}

export class LoadDealerDetails implements Action {
  readonly type: string = DealerTypes.LOAD_DEALER_DETAILS;

  constructor() {
  }
}

export class LoadDealerDetailsSuccess implements Action {
  readonly type: string = DealerTypes.LOAD_DEALER_DETAILS_SUCCESS;

  constructor(public payload: { dealerInfo: IDealerInfo }) {
  }
}

export class LoadDealerDetailsFail implements Action {
  readonly type: string = DealerTypes.LOAD_DEALER_DETAILS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadSettings implements Action {
  readonly type: string = DealerTypes.LOAD_SETTINGS;

  constructor(public payload: { departments: ('sales'|'service'| string) }) {
  }
}

export class LoadSettingsSuccess implements Action {
  readonly type = DealerTypes.LOAD_SETTINGS_SUCCESS;

  constructor(public payload: { settings: Settings }) {
  }
}

export class LoadSettingsFailure implements Action {
  readonly type = DealerTypes.LOAD_SETTINGS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadStatesDetails implements Action {
  readonly type: string = DealerTypes.LOAD_STATES;

  constructor() {
  }
}

export class LoadStatesDetailsSuccess implements Action {
  readonly type: string = DealerTypes.LOAD_STATES_SUCCESS;

  constructor(public payload: { states: StateDetails[] }) {
  }
}

export class LoadStatesDetailsFail implements Action {
  readonly type: string = DealerTypes.LOAD_STATES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadCityDetails implements Action {
  readonly type: string = DealerTypes.LOAD_CITIES;

  constructor() {
  }
}

export class LoadCityDetailsSuccess implements Action {
  readonly type: string = DealerTypes.LOAD_CITIES_SUCCESS;

  constructor(public payload: { cities: { [key: string]: string[]} }) {
  }
}

export class LoadCityDetailsFail implements Action {
  readonly type: string = DealerTypes.LOAD_CITIES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadFetchapikey implements Action {
  readonly type: string = DealerTypes.LOAD_FETCH_API_KEY;

  constructor(public keyType : string ) {
  }
}

export class LoadFetchapikeySuccess implements Action {
  readonly type: string = DealerTypes.LOAD_FETCH_API_KEY_SUCCESS;

  constructor(public payload: { fetchApiKey: any }) {
  }
}

export class LoadFetchapikeyFail implements Action {
  readonly type: string = DealerTypes.LOAD_FETCH_API_KEY_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export type DealerType =
  LoadDealerDetails
  | LoadDealerDetailsSuccess
  | LoadDealerDetailsFail
  | LoadSettings
  | LoadSettingsSuccess
  | LoadSettingsFailure
  | LoadStatesDetails
  | LoadStatesDetailsSuccess
  | LoadStatesDetailsFail
  | LoadCityDetails
  | LoadCityDetailsSuccess
  | LoadCityDetailsFail
  | LoadFetchapikey
  | LoadFetchapikeySuccess
  | LoadFetchapikeyFail
  ;
