import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AppointmentBookingSource, AppointmentType, IAppointment, IResponse} from '@signal/asp-data-commons';
import {DealerState} from '../../../store/dealer/dealer.reducer';
import {selectDealerCode} from '../../../store/dealer/dealer.selectors';
import {Store} from '@ngrx/store';
import { environment } from '../util.service';
import { DateTime } from 'luxon';
import { SourceOfApptChecklist } from '@signal/asp-data-commons/lib/enums/enum';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private  readonly aspB2cGuestApi: string;

  constructor(private readonly http: HttpClient,
              private readonly dealerState: Store<DealerState>) {
    this.aspB2cGuestApi = environment.aspB2cGuestApi;
  }

  getUriPathFromApptType(apptType: AppointmentType) {
    return apptType.toLowerCase().replace(' ', '');
  }

  /**
   * Creates the appointment in the backend
   *
   * @param appointment appointment to be created
   */
  create(appointment: IAppointment): Observable<IResponse<IAppointment>> {
    const apptType = this.getUriPathFromApptType(appointment.appointmentType);
    appointment.appointmentBookingSource = AppointmentBookingSource.ASP;
    appointment.appointmentCreatedBy = 'Customer';
    appointment.appointmentCreatedByUserName = 'Customer';
    appointment.appointmentCreatedDate = DateTime.now().toFormat('yyyy-MM-dd');
    /* Remove emailAdrress or phoneNumber if empty while creating testdrive appt */
    if(appointment.appointmentType === AppointmentType.TEST_DRIVE || appointment.appointmentType === AppointmentType.VEHICLE_INQUIRY)
    {
      this.removeEmptyKeys(appointment);
    }
    
    if(appointment.appointmentType === AppointmentType.DELIVERY) {
      appointment.sourceOfReq = SourceOfApptChecklist.DEL_APPT_UI;
    }
    // TODO: Appending country code temporarily.
    // appointment.customer.phoneNumber = `${appointment.customer.phoneNumber}`;

    /* Getting the dealer info and setting it in the appointment to be created */
    return this.dealerState.select(selectDealerCode).pipe(switchMap(code => {
      const apptWithDealerInfo = {
        ...appointment,
        dealerCode: code
      };
      return this.http.post(`${this.aspB2cGuestApi}appointment/${code}/${apptType}`, apptWithDealerInfo).pipe(
        map((data: IResponse<IAppointment>) => {
            return data;
          }
        )
      );
    }));

  }

  /**
   * Search the appointments by given search text and type
   *
   * @param query query string to be searched for getting the appointments
   */
  search(query: string): Observable<IResponse<IAppointment[]>> {
    const c = `${new Date().getTime()}`;
    return this.dealerState.select(selectDealerCode).pipe(switchMap(dealerCode => {
      return this.http.get(`${this.aspB2cGuestApi}appointment/${dealerCode}/customer/search`, {
        params: {query, c}
      }).pipe(
        map((data: IResponse<IAppointment[]>) => {
            return data;
          }
        )
      );
    }));
  }

  /**
   * Cancel the appointment in the backend
   *
   * @param appointment appointment to be cancelled
   */
  cancel(appointment: IAppointment): Observable<IResponse<IAppointment>> {
    const apptType = this.getUriPathFromApptType(appointment.appointmentType);
    const dealerCode = appointment.dealerCode;
    return this.http.delete(`${this.aspB2cGuestApi}appointment/${dealerCode}/${apptType}/${appointment.appointmentId}`).pipe(
      map((data: IResponse<IAppointment>) => {
          return data;
        }
      )
    );
  }

  /**
   * update the appointment in the backend
   *
   * @param appointment the appointment to be updated
   */
  update(appointment: IAppointment): Observable<IResponse<IAppointment>> {
    const apptType = this.getUriPathFromApptType(appointment.appointmentType);
    const dealerCode = appointment.dealerCode;
    appointment.appointmentBookingSource = AppointmentBookingSource.ASP;
    appointment.appointmentCreatedBy = 'Customer';
    appointment.appointmentCreatedDate = DateTime.now().toFormat('yyyy-MM-dd');
    /* Remove emailAdrress or phoneNumber if empty while editing testdrive appt */
    if(appointment.appointmentType === AppointmentType.TEST_DRIVE)
    {
      this.removeEmptyKeys(appointment);
    }

    if(appointment.appointmentType === AppointmentType.DELIVERY) {
      appointment.sourceOfReq = SourceOfApptChecklist.DEL_APPT_UI;
    }
    // TODO: Appending country code temporarily.
    // appointment.customer.phoneNumber = `${appointment.customer.phoneNumber}`;

    /* Removing forbidden properties for update */
    // ['dealer', 'dealerCode', 'stockNumber'].forEach(p => delete appointment[p]);

    return this.http.put(`${this.aspB2cGuestApi}appointment/${dealerCode}/${apptType}/${appointment.appointmentId}`, appointment).pipe(
      map((data: IResponse<IAppointment>) => {
          return data;
        }
      )
    );
  }

  /**
   * get the appointment
   *
   * @param apptType Appointment type (either SERVICE, TEST_DRIVE, DELIVERY)
   * @param appointmentId Appointment Id to be get from backend
   */
  get(apptType: AppointmentType, appointmentId: string): Observable<IResponse<IAppointment>> {
    return this.dealerState.select(selectDealerCode).pipe(switchMap(code => {
      const apptTypeUri = this.getUriPathFromApptType(apptType);
      return this.http.get(`${this.aspB2cGuestApi}appointment/${code}/${apptTypeUri}/${appointmentId}`).pipe(
        map((data: IResponse<IAppointment>) => {
            return data;
          }
        )
      );
    }));

  }
  /**
   * Confirm the appointment
   */
   confirmAppointment(appointment: IAppointment): Observable<IResponse<IAppointment>> {
    const dealerCode = appointment.dealerCode;
    return this.http.patch(`${this.aspB2cGuestApi}appointment/${dealerCode}/confirmAppointment/${appointment.appointmentId}`,appointment).pipe(
      map((data: IResponse<IAppointment>) => {
          return data;
        }
      )
    );
  }

  removeEmptyKeys(appointment: any) {
    if(appointment.customer.emailAddress ==='')
    {
      delete appointment.customer.emailAddress;
      delete appointment.emailAddress;
    }
    else if(appointment.customer.phoneNumber ==='')
    {
      delete appointment.customer.phoneNumber;
    }
  }
}
