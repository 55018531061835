<section id="availability-form">
  <form [formGroup]="form">
    <div class='language-advisor-times'>
      <section class='language-advisor' [ngClass]="{'disabled': lockSalesConsultant === 'Lock' }">
          

        <div class='options'>
          <div class="col-language pr-3">
            <span class="label-language-advisor">{{'transportation.preferredLanguage' | translate }} </span>
            <mat-form-field appearance="outline" class="language">
              <!-- <mat-label>{{'transportation.preferredLanguage' | translate }}</mat-label> -->
              <mat-select formControlName="languageId" (selectionChange)="languageChanged($event)">
                <mat-option *ngFor='let language of languages' [value]="language.languageId">
                  {{getLanguage(language.language)}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
         

          <div class="col-language">
            <span *ngIf="!hideSalesConsultant" class="label-language-advisor">{{options.department?.toUpperCase() === 'SERVICE'? ('transportation.serviceAdvisor' | translate | titlecase) :('transportation.salesAdvisor' | translate | titlecase)}} </span>
            <mat-form-field appearance="outline" class="advisor" *ngIf="!hideSalesConsultant">
              <mat-select formControlName="advisorId" [(value)]="selected" (selectionChange)="advisorChanged($event)">
                <mat-select-trigger>
                  <span *ngFor='let advisor of advisors'>
                    <img *ngIf='advisor.id == selected' [src]='advisor.avatar'
                       class="selectedadvisor-profile-pic"
                       (error)="getAssetUrl($event)"
                       alt=""/>
                       <span *ngIf='advisor.id == selected'>{{advisor.displayName == 'Any advisor' ? (options.department?.toUpperCase() === 'SERVICE'? ('transportation.anyAdvisor' | translate):('transportation.anyConsultant' | translate)) : advisor.displayName}}</span>
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor='let advisor of advisors' [value]='advisor.id'>
                  <img [src]='advisor.avatar'
                       class="advisor-profile-pic"
                       (error)="getAssetUrl($event)"
                       alt=""/>
                       {{advisor.displayName == 'Any advisor' ? (options.department?.toUpperCase() === 'SERVICE'? ('transportation.anyAdvisor' | translate):('transportation.anyConsultant' | translate)) : advisor.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </section>
      <section class="suggested-time-section" *ngIf="source !== 'sales'">
        <ng-template #suggestedTimes>
          <div class="suggested-times-parent">
            <div class='suggested-times' *ngFor='let suggestedTime of availability.suggestedTimes'>
              <mat-radio-group aria-label="Select an option" name="timeSlotRadio">
               <div class="timeSlot" *ngFor='let timeSlot of suggestedTime.timeSlots'>
                <mat-radio-button disableRipple class='suggested-time' [value]="timeSlot.timeSlotId"
                              [checked]="form.get('appointmentStartDate').value.slice(0, 10) == suggestedTime.date && form.get('timeSlotId').value == timeSlot.timeSlotId"
                              (change)="onSuggestedTimesSelected(suggestedTime.date, timeSlot.timeSlotId)">
                <span>
                  <div class='sdate'>{{getTranslation(suggestedTime.date |  date: 'EEEE')}},{{getTranslation(suggestedTime.date |  date: 'MMMM')}} {{suggestedTime.date |  date: 'd'}}</div>
                  <div class='stime'>{{timeSlot.time}}</div>
                </span>
                </mat-radio-button>
              </div>
            </mat-radio-group>
            </div>
          </div>
        </ng-template>
      </section>
      <section class='show-calendar pl-2' *ngIf="source !== 'sales'">
        <div class='calendar rounded-corners'>
          <app-asp-calendar [formControlName]="'appointmentStartDate'"
                            [isAvailabilityLoading]="isAvailabilityLoading"
                            [startAt]="availability.dayStartAt"
                            [minDate]="availability.minDate"
                            [maxDate]="availability.maxDate"
                            [availableDates]="availableDates"
                            [closedDates]="availability.offLeaveDays"
                            (monthChanged)="onMonthChange($event)">
          </app-asp-calendar>
        </div>
        <div class='slot-appointments'>
          <asp-time-slot [timeSlots]="timeSlots"
                         [isAvailabilityLoading]="isAvailabilityLoading"
                         [formControlName]="'timeSlotId'"
                         [isTimeSlotDisabled]="isTimeSlotDisabled"
                         (timeSlotValidator)="emitTimeSlotValidator($event)"></asp-time-slot>
        </div>
      </section>
    </div>
  </form>
</section>
