import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, ControlValueAccessor} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SalesAppointmentState } from '../../../store/sales-appointment/sales-appointment.reducer';
import { AppointmentType, ICustomer } from '@signal/asp-data-commons';
import { SubscriptionList } from '../../models/asp.types';
import { unsubscribeSubscriptions } from '../../services/util.service';
import { AuthService } from './../../services/auth.service';
import { Observable } from 'rxjs';
import { DealerState } from '../../../store/dealer/dealer.reducer';
import * as fromSelectors from '../../../store/dealer/dealer.selectors';
import { AnalyticsService } from './../../../shared/services/analytics.service';
import { environment } from '../../../../modules/shared/services/util.service';
@Component({
  selector: 'app-contact-email-info',
  templateUrl: './contact-email-info.component.html',
  styleUrls: ['./contact-email-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactEmailInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactEmailInfoComponent),
      multi: true
    }
  ]
})
export class ContactEmailInfoComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() showContactInfo: boolean = true;
  registerUrl = '';
  contactEmailInfo: UntypedFormGroup;
  preferredCountries: string = this.translate.currentLang ? this.translate.currentLang.split('-')[1].toLowerCase(): 'us';
  subscriptions: SubscriptionList = {};
  // @Output() formEdited:EventEmitter<boolean> = new EventEmitter();
  signedIn: boolean;
  hoursOfOperation$: Observable<string>;
  dealerName$: Observable<string>;
  dealerPhone$: Observable<string>;
  dealerAddress$: Observable<string>;
  amenities$: Observable<string[]>;
  @Input() apptType:string;
  required:boolean;

  constructor( private readonly formBuilder: UntypedFormBuilder,
    private readonly translate: TranslateService,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly authService: AuthService,
    private readonly analyticsService : AnalyticsService,
    private readonly dealerState: Store<DealerState>
    ) { 
      this.registerUrl = environment.registerUrl;
      this.subscriptions.auth = this.authService.isSignedIn.subscribe(loggedIn => {
        this.signedIn = loggedIn;
      });
    /* Dealer details from State*/
    this.getStateValue();
    this.initializeForm();

  }

  ngOnInit(): void {
    this.subscriptions.formChangeSub1 = this.contactEmailInfo.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
      // this.formEdited.emit(!this.contactEmailInfo.dirty);
    });
    this.required= this.apptType===AppointmentType.TEST_DRIVE ? false : true;
    /* remove phone and email required validation for test drive appt */
    if(!this.required)
    {
      this.contactEmailInfo.controls['phoneNumber'].setValidators([]);
      this.contactEmailInfo.controls['phoneNumber'].updateValueAndValidity();
      this.contactEmailInfo.controls['emailAddress'].setValidators([Validators.email]);
      this.contactEmailInfo.controls['emailAddress'].updateValueAndValidity();
    }
  }

  getStateValue() {
    this.dealerName$ = this.dealerState.select(fromSelectors.selectDealerName);
    this.dealerPhone$ = this.dealerState.select(fromSelectors.selectDealerPhoneNumber);
    this.dealerAddress$ = this.dealerState.select(fromSelectors.selectDealerAddress);
    this.hoursOfOperation$ = this.dealerState.select(fromSelectors.selectSalesOperationalHours);
    this.amenities$ = this.dealerState.select(fromSelectors.selectSalesAmenities);
  }

  initializeForm() {
    this.contactEmailInfo = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]]
      });
  }

  login() {
    this.authService.loginB2c();
  }

  signUp() {
    const linkTrackingData = {
      content_section: 'navigation',
      link_module: 'nav',
      link_text: 'sign up clicked'
    }
    this.analyticsService.trackLink(linkTrackingData, true)
  }

  onChange = (value: ICustomer) => { };
  onTouched = () => { };

  get value(): ICustomer {
    return this.contactEmailInfo.value;
  }

  set value(value: ICustomer) {
    this.contactEmailInfo.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  registerOnChange = (fn: any) => {
    this.onChange = fn;
  }

  validate(_: UntypedFormControl) {
    return this.contactEmailInfo.valid ? null : { customer: { valid: false } };
  }

  get f() {
    return this.contactEmailInfo.controls;
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subscriptions);
  }

}
