<!-- <mat-expansion-panel id="vehicle-expansion" [expanded]="!isEditMode"> -->
  <!-- <mat-expansion-panel-header> -->
    <mat-panel-title class="step-title">
      {{ 'selectService.chooseVehicle' | translate}}
    </mat-panel-title>
  <!-- </mat-expansion-panel-header> -->

  <form [formGroup]="step"
        *ngIf="!(isUserVehiclesLoading$ | async) && !(modelYearAndModelsLoading$ | async) ;else loader"
        autocomplete="off">
    <div id="select-vehicle-container">
      <div class="accordion">
        <div class="accordion-header">
          <div class="title">
            <div class="step-title anime-element anime-prime"> {{ title }}</div>
          </div>
          <div
            *ngIf="signedIn ?  !(isEditMode$| async) : false"
            id="vehicle-type-switcher"
            (click)="toggleVehicleType()">
            {{ switcherText }}
            <span class="arrow"></span>
          </div>
        </div>
        <div class="accordion-panel">
          <ng-container *ngIf = 'isEditMode$ | async'>
            <div class="edit-mode-vehicle">
              <mat-icon class="mat-icon">info_outline</mat-icon>
              <span>{{ 'welcome.vehicleDetailsCanNotBeModifiedExceptMileage' | translate}}</span>
            </div>
          </ng-container>

          <!-- Own vehicle input form -->
          <ng-container *ngIf="isOwnVehicle">
            <div class="own-vehicle-error"
                 *ngIf="vehicles.length > 1 && !vehicleSelect">
                 {{step.errors?.required | translate}}
            </div>
            <div id="own-vehicles-container">
              <ng-container *ngIf="vehicles.length; else noVehicleFound">
                <div class="own-vehicle" *ngFor="let vehicle of vehicles">
                  <label>
                    <input type="radio"
                        
                           [value]="vehicle.vin"
                           [checked]="step.value?.vin === vehicle.vin"
                           (change)="vehicleSelected(vehicle, vehicleCardComponent)"
                           formControlName="vin"/>
                    <div class="vehicle-card-container anime-element">
                      <app-learning-vehicle-card #vehicleCardComponent
                        [preSelectedVin] = "preSelectedVin"
                        [vehicle]="vehicle"
                        [newvehicle]="newvehicle"
                        (fieldChange)="onVehicleFieldChange($event, vehicle)"
                        (toShowFooterDetails)="toShowFooterDetails($event)"
                        [editmode]="(isEditMode$ | async)"
                      ></app-learning-vehicle-card>
                    </div>
                  </label>
                </div>
              </ng-container>
              <ng-template #noVehicleFound>
                <div class="vehicle-not-found">
                  <mat-icon class="icon">info</mat-icon>
                  <div class="text">{{'welcome.vehicleNotFound' | translate}}</div>
                  <div class="text-sec">
                    {{'welcome.thereAreNoVehiclesAssociatedWithTheCustomer' | translate}}
                    {{'welcome.pleaseChooseVehicleDetailsFromChooseAnotherVehicleSectionAbove' | translate}}
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>

          <!-- Different vehicle input form -->
          <ng-container *ngIf="!isOwnVehicle || (isEditMode && isVehicleAtOwnedVehicles())">
            <div id="different-vehicle-container">
              <div class="make-year-model-container">

              <ng-container *ngIf="isEditMode">
               
                  <!-- Input: make -->
                  <div class="field-input">
                      <!-- <label>{{ 'welcome.make' | translate}}</label> -->
                      <mat-form-field class="mat-input-container anime-element" appearance="outline">
                        <input matInput [placeholder]="'welcome.make' | translate" formControlName="make" 
                        [value]="step.controls.make" />
                      </mat-form-field>
                  </div>
      
                  <!-- based on make show  text input -->
                  <ng-container *ngIf="!isMakeOther; else textInputForOther">
                    <!-- Input: Year -->
                    <div class="field-input">
                      <mat-form-field class="mat-input-container anime-element anime-sec"
                          appearance="outline">
                          <input matInput [placeholder]="'testDrive.year' | translate" formControlName="year"
                          [value]="step.controls.year" />
                      </mat-form-field>
                    </div>
                    <!-- Input: Model -->
                    <div class="field-input">
                      <!-- <label>{{ 'welcome.model' | translate}}</label> -->
                      <mat-form-field
                      class="mat-input-container anime-element anime-sec"
                      appearance="outline">
                      <input matInput [placeholder]="'welcome.model' | translate" formControlName="model" 
                      [value]="step.controls.model" />
                     
                    </mat-form-field>
                    </div>
              
                  </ng-container>
                
              
              </ng-container>

              <ng-container *ngIf="!isEditMode">
               
                  <!-- Input: make -->
                  <div class="field-input">
                      <!-- <label>{{ 'welcome.make' | translate}}</label> -->
                      <mat-form-field class="mat-input-container anime-element" appearance="outline">
                        <mat-select formControlName="make" [placeholder]="'welcome.make' | translate">
                          <mat-option *ngFor="let make of makes" [value]="make.name">
                            {{ make.name | titlecase}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="step.controls.make.errors?.required">{{ 'welcome.makeIsRequired' | translate}}</mat-error>
                      </mat-form-field>
                  </div>
  
                  <!-- based on make show drop down or text input -->
                  <ng-container *ngIf="!isMakeOther; else textInputForOther">
                    <!-- Input: Year -->
                    <div class="field-input">
                      <!-- <label>{{ 'welcome.modelYear' | translate}}</label> -->
                      <mat-form-field class="mat-input-container anime-element anime-sec"
                          appearance="outline">
                      <mat-select formControlName="year" [placeholder]="'testDrive.year' | translate">
                        <mat-option *ngFor="let s of yearmodels" [value]="s">
                          {{ s }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate}}</mat-error>
                  </mat-form-field>
                  </div>
                  <!-- Input: Model -->
                    <div class="field-input">
                      <mat-form-field
                      class="mat-input-container anime-element anime-sec"
                      appearance="outline">
                      <mat-select formControlName="model" [placeholder]="'welcome.model' | translate">
                        <mat-option *ngFor="let model of models" [value]="model"> {{ model }}
                          </mat-option>
                      </mat-select>
                      <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate}}</mat-error>
                    </mat-form-field>
                    </div>
  
                  </ng-container>
                </ng-container>

                  <ng-template #textInputForOther>
                    <!-- Input: Year -->
                    <div class="field-input">
                      <!-- <label>{{ 'welcome.makeName' | translate }}</label> -->
                    <mat-form-field
                      class="mat-input-container anime-element anime-sec"
                      appearance="outline">
                      <input
                        matInput
                        [placeholder]="'welcome.make' | translate"
                        formControlName="otherMake"
                      />
                      <mat-error *ngIf="step.controls.otherMake.errors?.required">{{ 'welcome.makeNameIsRequired' | translate}}</mat-error>
                    </mat-form-field>
                    </div>
  
                    <!-- Input: Year -->
                    <div class="field-input">
                      <!-- <label>{{ 'welcome.modelYear' | translate}}</label> -->
                      <mat-form-field
                      class="mat-input-container anime-element anime-sec"
                      appearance="outline">
                      <input
                        matInput
                        [placeholder]="'testDrive.year' | translate"
                        formControlName="year"
                        aspNumberOnly
                      />
                      <mat-error *ngIf="step.controls.year.errors?.required">{{ 'welcome.yearIsRequired' | translate}}</mat-error>
                    </mat-form-field>
                    </div>
  
                    <!-- Input: Model -->
                    <div class="field-input">
                      <!-- <label>{{ 'welcome.model' | translate}}</label> -->
                      <mat-form-field
                      class="mat-input-container anime-element anime-sec"
                      appearance="outline">
                        <input
                          matInput
                          [placeholder]="'welcome.model' | translate"
                          formControlName="model"/>
                        <mat-error *ngIf="step.controls.model.errors?.required">{{ 'welcome.modelIsRequired' | translate}}</mat-error>
                      </mat-form-field>
                    </div>
                  </ng-template>
                </div>
                <!-- make-year-model-container -->
  
                <div id="or" class="anime-element">{{ 'welcome.or' | translate}}</div>
  
                <!-- Input: VIN -->
                <div class="vin-container">
                  <div class="field-input">
                    <!-- <label>{{'welcome.enter17digitVIN' | translate}}<span *ngIf="isVinMandate" style="color: red">*</span></label> -->
                    <mat-form-field class="mat-input-container anime-element" appearance="outline">
                    <input
                      matInput
                      placeholder="{{'welcome.enter17digitVIN' | translate}}"
                      formControlName="vin"
                      [required]="isVinMandate"
                    />
                    <mat-error *ngIf="step.controls.vin.errors?.required">{{'welcome.vinIsRequired' | translate}}</mat-error>
                    <mat-error
                      *ngIf=" step.controls.vin.errors?.pattern17DigitAndAlphaNumeric"
                    >{{step.controls.vin.errors?.pattern17DigitAndAlphaNumeric | translate}}</mat-error>
                    <mat-error
                      *ngIf=" step.controls.vin.errors?.pattern17Digit"
                    >{{step.controls.vin.errors?.pattern17Digit | translate}}</mat-error>
                    <mat-error
                      *ngIf=" step.controls.vin.errors?.patternAlphaNumeric"
                    >{{step.controls.vin.errors?.patternAlphaNumeric | translate}}</mat-error>
                  </mat-form-field>
                  <div *ngIf="hasExistingAppt" style="font-size: 14px;color: red;padding: 0 1em;">
                    <sup >   {{existingApptText}}</sup>
                  </div>
                  </div>
                </div>
            
              <!-- Input: Mileage -->
              <div *ngIf="showMileageField">
              <div class="anime-element">
                <div class="mileage-input-title">{{'welcome.enterYourMileage' | translate}}
                  <span style="color: red">*</span>
                </div>
                <div class="mileage-input-hint">
                  {{'welcome.provideAppropriateMileage' | translate}}
                </div>
              </div>
              <mat-form-field class="mat-input-container anime-element" appearance="outline">
                <input
                  matInput
                  placeholder="{{milesPlaceholder}}"
                  formControlName="mileage"
                  aspNumberOnly
                  (input)="onKeyPress($event)"
                />
                <mat-error *ngIf="step.controls.mileage.errors?.required">{{ milesIsRequired }}</mat-error>
              </mat-form-field>
            </div>
          </div>
            <!-- different-vehicle-container -->

          </ng-container>
        </div>
        <!-- accordion panel -->
      </div>
    </div>
  </form>
  <ng-template #loader>
    <div id="schedule-rotation-loader">
      <app-loading loadingSymbol="dots"
                         text="{{ 'dashboardAppointments.loadingMyVehicles' | translate | titlecase}}"
                         color="var(--dashboard-primary-color)"
                         size="1">
            </app-loading>
    </div>
  </ng-template>
<!-- </mat-expansion-panel> -->
