import { AuthService } from '../shared/services/auth.service';
import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { Store } from '@ngrx/store';
import { IFooterSummary } from '../store/sales-appointment/sales-appointment.reducer';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SubscriptionList } from '../shared/models/asp.types';
import * as util from '../shared/services/util.service';
import { DashboardState } from '../store/dashboard/dashboard.reducer';
import * as fromActions from '../store/sales-appointment/sales-appointment.actions';
import * as fromSelectors from '../store/sales-appointment/sales-appointment.selectors';
import * as dashboardActions from '../store/dashboard/dashboard.actions';
import { AppointmentStatus, AppointmentType, Department, IDeliveryAppointment, IGeneralSettingsAppointmentRequest } from '@signal/asp-data-commons';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { GLOBAL_CONSTANT } from '../shared/services/util.service';
import { ISalesAppointmentForm, SalesAppointmentState } from '../store/sales-appointment/sales-appointment.reducer';
import { VehicleCustomerInfoComponent } from './vehicle-customer-info/vehicle-customer-info.component';
import { SalesAppointmentTimeComponent } from '../shared/components/sales-appointment-time/sales-appointment-time.component';
import { selectDealerCode } from '../store/dealer/dealer.selectors';
import { DealerState } from '../store/dealer/dealer.reducer';
import * as fromDealerSelector from '../store/dealer/dealer.selectors';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  isEditMode = false;
  isSubmitting = false;
  selectedIndex = 0;
  continueBtnTrans = 'selectService.continue';
  continueBtnText: string[] = [this.continueBtnTrans, this.continueBtnTrans, /*this.continueBtnTrans, */ 'appointmentInformation.submit', 'appointmentInformation.submitting'];
  disclaimerToggle = false;
  isEditMode$: Observable<boolean>;
  time$: Observable<any>;
  contracting:number = 0;
  transport;
  salesAppointmentFooterSummaries$: Observable<IFooterSummary[]>;
  toShowFooterAppointment: boolean = true;
  breadcrumbSteps: { name: string, id: number }[] = [
    { name: 'selectService.vehicle', id: 0 },
    // { name: 'delivery.checkList', id: 1 },
    { name: 'selectService.appointment', id: 1 },
    { name: 'selectService.confirm', id: 2 }
  ];
  @ViewChild(VehicleCustomerInfoComponent, { static: false }) vehicleCustomerInfoComponent: VehicleCustomerInfoComponent;
  // @ViewChild(ChecklistComponent, { static: false }) checklistComponent: ChecklistComponent;
  @ViewChild(SalesAppointmentTimeComponent, { static: false }) salesAppointmentTimeComponent: SalesAppointmentTimeComponent;

  signedIn = false;
  private signedInUserEmail;
  appointmentId: string;
  subs: SubscriptionList = {};
  private editDetails: { appointmentId: string };
  private lastChecklistSelectionDuration: number;
  department: string = Department.SALES;
  profile: any;
  checklistDuration: { min: number; };
  dealerCd$: Observable<string>;
  dealerCode: string;
  apptType = AppointmentType.DELIVERY;
  isFromSaved : boolean = false;
  isLoading: boolean = false;
  lockSalesConsultant = "";
  settings:IGeneralSettingsAppointmentRequest={} as IGeneralSettingsAppointmentRequest;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly bannerService: AspBannerService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dashboardState: Store<DashboardState>,
    private readonly salesAppointmentState: Store<SalesAppointmentState>,
    private readonly authService: AuthService,
    private readonly dealerState: Store<DealerState>
  ) {
    this.dealerState.select(fromDealerSelector.selectSalesDetails).subscribe((data:any) =>{
      this.lockSalesConsultant = data?.lockSalesConsultant ? data?.lockSalesConsultant : "";
    });
    this.subs.authSub = this.authService.user.subscribe(user => {
      this.signedIn = user && !this.authService.isExpired();
      if (this.signedIn) {
        this.signedInUserEmail = user?.email;
        this.profile = user;
        let payload: { queryText: string };
        payload = {
          queryText: user.email,
        };
      }
    });

    this.subs.route = this.activatedRoute.queryParams.subscribe(params => {
      this.editDetails = { appointmentId: params.id };
      this.isEditMode = !!this.editDetails.appointmentId;
      this.appointmentId = this.editDetails.appointmentId;
      this.isFromSaved = params?.isFromSaved ? true : false;

      if (this.isEditMode) {
        /* Setting the edit mode actions in async stack, to allow store to be ready first */
        this.salesAppointmentState.dispatch(new fromActions.GetEditData
          ({ appointmentType: AppointmentType.DELIVERY, appointmentId: this.editDetails.appointmentId }));
          this.isLoading = this.isFromSaved ? true : false;
      }
    });

  }

  ngOnInit(): void {
    this.subs.status = this.salesAppointmentState.select(fromSelectors.selectAppointmentStatus).subscribe(status => {
      if (status && this.isFromSaved) {
        if (status !== AppointmentStatus.SAVED) {
          util.navigateToRoutePromise(util.getRouteFromWindow('delivery') ? `${util.getRouteFromWindow('delivery')}/confirm` : '/delivery/confirm', this.router, {
            queryParams: {
              id: this.appointmentId,
              isFromMail: true,
              isManageMail: true
            }
          });
        }
        else {
          this.isLoading = false;
        }
      }
    })
    this.setupStore();
  }

  ngAfterViewInit() {
    this.bannerService.show();
  }

  switchTab(tabIndex?) {
    if (this.toShowFooterAppointment) {
      if (tabIndex !== undefined && tabIndex >= 0) {
        setTimeout(() => {
          this.setSelectedIndex(tabIndex);
        });
      } else {
        if (!this.isValidForm()) {
          return false;
        }
        setTimeout(() => {
          this.setSelectedIndex(this.selectedIndex + 1);
          if (this.selectedIndex === 3) {
            this.onSubmit();
          }
        });
      }
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    if (this.isEditMode) {
      this.salesAppointmentState.dispatch(new fromActions.EditSalesAppointment({ appointmentType: AppointmentType.DELIVERY, appointmentId: null,settings:this.settings }));
    } else {
      this.salesAppointmentState.dispatch(new fromActions.CreateSalesAppointment({ appointmentType: AppointmentType.DELIVERY,settings:this.settings }));
    }
    this.subs.editDetailsSub = this.salesAppointmentState.select(fromSelectors.editDetails)
      .subscribe((editDetail) => {
        this.onSubmitting(editDetail);
      });
  }

  onSubmitting(data) {
    if (data) {
      this.isSubmitting = false;
      if (data.isSubmitted) {
        this.appointmentId = data.appointmentId;
        const appointment: IDeliveryAppointment = data.appointment;
        const navigationExtras: NavigationExtras = {
          queryParams: {
            id: this.appointmentId,
          },
          state: appointment
        };
        this.dashboardState.dispatch(new dashboardActions.UpsertAppointment({ appointment: appointment }));
        util.navigateToRoutePromise(util.getRouteFromWindow('delivery') ? `${util.getRouteFromWindow('delivery')}/confirm` : '/delivery/confirm', this.router, navigationExtras);
      }
    }
  }

  setSelectedIndex(index: number) {
    this.selectedIndex = index;
    this.salesAppointmentState.dispatch(new fromActions.UpdateCurrentStep({ step: index }));

    if (index === 2) {
      this.bannerService.hideCarImage();
    } else if (index === 1) {
      this.checkAndUpdateAvailability();
      this.bannerService.showCarImage();
    } else {
      this.bannerService.showCarImage();
    }
  }

  private checkAndUpdateAvailability() {
    this.subs.appointmentFormSub = this.salesAppointmentState.select(fromSelectors.appointmentDetails)
      .pipe(take(1))
      .subscribe((appointment: ISalesAppointmentForm) => {
        this.transport = appointment.transportationTypeCode;
        /* Need to fetch availability only when the checklist selection has changed */
        const currentOpcodeSelectionDuration = this.checklistDuration.min;

        if (_.isEqual(this.lastChecklistSelectionDuration, currentOpcodeSelectionDuration)) {
          return;
        }
        this.lastChecklistSelectionDuration = currentOpcodeSelectionDuration;

        /* If so, recalculate the availability */
        const chosenDate = appointment.availability.appointmentStartDate;
        const advisorId = appointment.availability.advisorId || '-1';
        if (!!chosenDate) {
          const date = DateTime.fromISO(chosenDate)
            .startOf('month')
            .toFormat('yyyy-MM-dd');
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date, advisorId, transport: this.transport, appointmentType: AppointmentType.DELIVERY, appointmentId: this.appointmentId }));
        } else {
          this.salesAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date: "", advisorId, transport: this.transport, appointmentType: AppointmentType.DELIVERY, appointmentId: this.appointmentId }
          ));
        }
      });
  }

  gotoBack() {
    this.setSelectedIndex(this.selectedIndex - 1);
  }

  isValidForm() {
    return this.isStepValid(this.selectedIndex);
  }

  checkNav(tabNo: number) {
    switch (tabNo) {
      case 0:
        return true;
      case 1:
        return this.areStepsValid(0);
      case 2:
        return this.areStepsValid(0, 1);
      // case 3:
      //   return this.areStepsValid(0, 1, 2);
      default:
        return false;
    }
  }

  areStepsValid(...steps: number[]) {
    for (const step of steps) {
      if (!this.isStepValid(step)) {
        return false;
      }
    }
    return true;
  }

  isStepValid(step: number) {
    switch (step) {
      case 0:
        return this.vehicleCustomerInfoComponent?.isValidForm();
      // case 1:
      //   return this.checklistComponent?.step?.valid;
      case 1:
        return this.salesAppointmentTimeComponent?.step.valid;
      case 2:
        return true
      default:
        return false;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  setupStore() {
    this.salesAppointmentState.dispatch(new fromActions.LoadAvailabilityOptions({appointmentType: AppointmentType.DELIVERY}));

    this.salesAppointmentFooterSummaries$ = this.salesAppointmentState.select(fromSelectors.selectSalesAppointmentFooterSummaries);
    this.time$ = this.dealerState.select(fromDealerSelector.time)
    this.subs.totaldurationsub = combineLatest([this.salesAppointmentState.select(fromSelectors.selectChecklistDurationForAvailability),this.time$]).subscribe(([duration,time]) => {
      this.contracting = time?.deliveryAppointmentDuration
      const dur = {...duration}
      dur.min = dur.min + this.contracting;
      this.checklistDuration = dur;
    })

    this.dealerCd$ = this.dealerState.select(selectDealerCode);
    this.subs.dealerCode = this.dealerCd$.subscribe(cd => {
      this.dealerCode = cd;
    })
    this.subs.currentStepSub = this.salesAppointmentState.select(fromSelectors.selectCurrentStep)
      .subscribe((currentStep: number) => {
        this.selectedIndex = currentStep;
      });
      this.dealerState.select(fromDealerSelector.dealerDetails).subscribe((data: any) => {
        if (data && Object.keys(data).length) {
          this.settings.dealerInfo = data.data;
        }
      });
      this.dealerState.select(fromDealerSelector.serviceSettings).subscribe((data: any) => {
        if (data && Object.keys(data).length) {
          this.settings.portal = data.portal;
          this.settings.time = data.time;
          this.settings.isAspCrmIntegrationEnabled = data.isAspCrmIntegrationEnabled;
          this.settings.isAspDmsIntegrationEnabled = data.isAspDmsIntegrationEnabled;
          this.settings.isConsumerPortalEnabled = data.isConsumerPortalEnabled;
          this.settings.isServiceAccessible = data.isServiceAccessible;
        }
      });
  }

  get getDisclaimerText() {
    return GLOBAL_CONSTANT.schedulingDisclaimerText;
  }

  toShowFooterDetails(event) {
    this.toShowFooterAppointment = event;
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
    this.bannerService.update('');
    this.salesAppointmentState.dispatch(new fromActions.ResetSalesAppointmentState());
  }

}

