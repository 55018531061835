<div id="time-slot">
    <div class='show-slots'>
      <div class="header-box">
        <div class='header-timing' [ngClass]="{'header-timing-selected' : selectedPartOfDay === PartOfTheDay.MORNING }"
          (click)="onPartOfDayChange(PartOfTheDay.MORNING)">
          <div class="icon-time-container">
            {{ 'transportation.morning' | translate}}
          </div>
        </div>
        <div class='header-timing' [ngClass]="{'header-timing-selected' : selectedPartOfDay === PartOfTheDay.AFTERNOON}"
          (click)="onPartOfDayChange(PartOfTheDay.AFTERNOON)">
          <div class="icon-time-container">
            {{ 'transportation.afternoon' | translate}}
          </div>
        </div>
        <div class='header-timing' [ngClass]="{'header-timing-selected' : selectedPartOfDay === PartOfTheDay.EVENING}"
          (click)="onPartOfDayChange(PartOfTheDay.EVENING)">
          <div class="icon-time-container">
            {{'transportation.evening' | translate }}
          </div>
        </div>
      </div>
      <div *ngIf="isAvailabilityLoading; else slots"
           class="slots-skeleton"></div>
      <ng-template #slots>
        <div class="slots" *ngIf="filteredTimeSlots?.length; else empty">
          <div class="slot" *ngFor="let item of filteredTimeSlots; let i = index;"
            [ngClass]="[selectedTimeSlot == item.timeSlotId && isTimeSlotDisabled ? 'slot-selected' : '', item.isOpen ? '' : 'slot-disabled']"
            (click)="onSlotChange(item)">{{item.time}}</div>
        </div>
        <ng-template #empty>
          <div class="empty" [style.backgroundImage]="emptyBgImg">
            <span>{{'transportation.pleaseSelectADateFromTheSelectedMonthToSeeTheAvailableTimeSlots' | translate}}</span>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
