import {Action} from '@ngrx/store';
import {ISalesAppointmentForm, SeriesDetailsForYear} from './sales-appointment.reducer';
import {VehicleColor} from '../../shared/models/sales-appointments/vehicle-color';
import {IAppointment, ICustomer, IAvailabilityOptions, IAvailability, AppointmentType, IVehicle, IChecklist, IGeneralSettingsAppointmentRequest} from '@signal/asp-data-commons';

export enum SalesAppointmentActionTypes {
  UPDATE_SALES_FORM_DATA = '[Sales page] sales form data updated in state',
  CREATE_SALES_APPOINTMENT = '[Sales page] create sales Appointment',
  CREATE_SALES_APPOINTMENT_SUCCESS = '[Sales page] create sales Appointment success',
  CREATE_SALES_APPOINTMENT_FAILURE = '[Sales page] create sales Appointment failure',
  GET_EDIT_DATA = '[Sales page] fetching edit details from DB',
  GET_EDIT_DATA_SUCCESS = '[Sales page] fetching edit details from DB success',
  GET_EDIT_DATA_FAILURE = '[Sales page] fetching edit details from DB failure',
  EDIT_SALES_APPOINTMENT = '[Sales page] edit sales Appointment',
  EDIT_SALES_APPOINTMENT_SUCCESS = '[Sales page] edit sales Appointment success',
  EDIT_SALES_APPOINTMENT_FAILURE = '[Sales page] edit sales Appointment failure',
  LOAD_AVAILABILITY_OPTIONS = '[Sales page] load Availability options',
  LOAD_AVAILABILITY_OPTIONS_SUCCESS = '[Sales page] load Availability options success',
  LOAD_AVAILABILITY_OPTIONS_FAILURE = '[Sales page] load Availability options failure',
  LOAD_AVAILABILITY = '[Sales page] load Availability',
  LOAD_AVAILABILITY_SUCCESS = '[Sales page] load Availability success',
  LOAD_AVAILABILITY_FAILURE = '[Sales page] load Availability failure',
  LOAD_SERIES_TRIM = '[EFC API] load Series and Trim details from EFC',
  LOAD_SERIES_TRIM_SUCCESS = '[EFC API] load Series and Trim details from EFC success',
  LOAD_SERIES_TRIM_FAILURE = '[EFC API] load Series and Trim details from EFC failure',
  LOAD_EXTERIOR_COLORS = '[EFC API] load Exterior colors details from EFC',
  LOAD_EXTERIOR_COLORS_SUCCESS = '[EFC API] load Exterior colors details from EFC success',
  LOAD_EXTERIOR_COLORS_FAILURE = '[EFC API] load Exterior colors details from EFC failure',
  RESET_SALES_APPOINTMENT_STATE = '[Sales page] reset Sales Appointment State',
  RESET_SALES_APPOINTMENT_FORM = '[Sales page] reset form value in State while routing',
  RESET_EXTERIOR_COLOR = '[Sales page] reset exterior color details while changing model',
  LOAD_CUSTOMER_DETAILS = '[Sales page] load signed in customer details in initial state',
  UPDATE_PATCH_SALES_FORM = '[Sales page] update sales vehicle or any value as per given path',
  LOAD_TESTDRIVE_VIN_VEHICLE = '[NVI/UVI testdrive API] load vehicle details on vin',
  LOAD_TESTDRIVE_VIN_VEHICLE_SUCCESS = '[NVI/UVI testdrive API] load vehicle details on vin success',
  LOAD_TESTDRIVE_VIN_VEHICLE_FAILURE = '[NVI/UVI testdrive API] load vehicle details on vin fail',
  LOAD_INVENTORY_VEHICLE = 'load vehicles from inventory',
  LOAD_INVENTORY_VEHICLE_SUCCESS = 'load inventory vehicle details success',
  LOAD_INVENTORY_VEHICLE_FAILURE = 'load inventory vehicle details failure',
  LOAD_ALL_CHECKLIST = 'load all checklist',
  LOAD_ALL_CHECKLIST_SUCCESS = 'load all checklist success',
  LOAD_ALL_CHECKLIST_FAILURE = 'load all checklist failure',
  CREATE_CHECKLIST = 'create checklist',
  UPDATE_CURRENT_STEP = '[Sales page] Update current step'
}

export class UpdateSalesFormData implements Action {
  readonly type: string = SalesAppointmentActionTypes.UPDATE_SALES_FORM_DATA;

  constructor(public payload: { value: ISalesAppointmentForm, appointmentType?: AppointmentType }) {
  }
}

export class CreateSalesAppointment implements Action {
  readonly type: string = SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT;

  constructor(public payload : {appointmentType: AppointmentType,settings:IGeneralSettingsAppointmentRequest}) {
  }
}

export class CreateSalesAppointmentSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT_SUCCESS;

  constructor(public payload: { appointmentModel: IAppointment }) {
  }
}

export class CreateSalesAppointmentFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.CREATE_SALES_APPOINTMENT_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class GetEditData implements Action {
  readonly type: string = SalesAppointmentActionTypes.GET_EDIT_DATA;

  constructor(public payload: { appointmentId: string , appointmentType: AppointmentType}) {
  }
}

export class GetEditDataSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.GET_EDIT_DATA_SUCCESS;

  constructor(public payload: { appointmentModel: IAppointment }) {
  }
}

export class GetEditDataFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.GET_EDIT_DATA_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class EditSalesAppointment implements Action {
  readonly type: string = SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT;

  constructor(public payload : {appointmentType: AppointmentType,appointmentId:string,settings:IGeneralSettingsAppointmentRequest}) {
  }
}

export class EditSalesAppointmentSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT_SUCCESS;

  constructor(public payload: { appointmentModel: IAppointment }) {
  }
}

export class EditSalesAppointmentFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.EDIT_SALES_APPOINTMENT_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAvailability implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY;

  constructor(public payload: {date: string, advisorId: string, transport: string, appointmentId: string, enddt?:string, vin?: string, appointmentType?: string,
  checklistDuration?: { min: number; }} = {date: '', advisorId: '-1', transport:undefined, appointmentId: '',enddt:'', vin: '', appointmentType: ''}) {
  }
}

export class LoadAvailabilitySuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY_SUCCESS;

  constructor(public payload: { availability: IAvailability, loadPayload: {date: string, advisorId: string, transport: string}}) {
  }
}

export class LoadAvailabilityFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAvailabilityOptions implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS;

  constructor(public payload: {appointmentType: string}) {
  }
}

export class LoadAvailabilityOptionsSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_SUCCESS;

  constructor(public payload: { availabilityOptions: IAvailabilityOptions }) {
  }
}

export class LoadAvailabilityOptionsFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_AVAILABILITY_OPTIONS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadSeriesAndTrim implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_SERIES_TRIM;

  constructor() {
  }
}


export class LoadInventoryVehicle implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE;

  constructor() {
  }
}

export class LoadInventoryVehicleSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE_SUCCESS;

  constructor(public payload: { vehicleDetails:any}) {
  }
}

export class LoadInventoryVehicleFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_INVENTORY_VEHICLE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class LoadSeriesAndTrimSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_SERIES_TRIM_SUCCESS;

  constructor(public payload: { seriesDetails: SeriesDetailsForYear[] }) {
  }
}

export class LoadSeriesAndTrimFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_SERIES_TRIM_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadExteriorColors implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS;

  constructor(public payload: { year: string, model: string, trim: string }) {
  }
}

export class LoadExteriorColorsSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS_SUCCESS;

  constructor(public payload: { exteriorColors: VehicleColor[] }) {
  }
}

export class LoadExteriorColorsFail implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_EXTERIOR_COLORS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class ResetSalesAppointmentForm implements Action {
  readonly type: string = SalesAppointmentActionTypes.RESET_SALES_APPOINTMENT_FORM;

  constructor() {
  }
}

export class ResetSalesAppointmentState implements Action {
  readonly type: string = SalesAppointmentActionTypes.RESET_SALES_APPOINTMENT_STATE;

  constructor() {
  }
}

export class ResetExteriorColors implements Action {
  readonly type: string = SalesAppointmentActionTypes.RESET_EXTERIOR_COLOR;

  constructor() {
  }
}

export class LoadCustomerDetails implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_CUSTOMER_DETAILS;

  constructor(public payload: { signedInUserDetails: ICustomer }) {
  }
}

export class UpdatePatchSalesForm implements Action {
  readonly type: string = SalesAppointmentActionTypes.UPDATE_PATCH_SALES_FORM;

  constructor(public payload: { path: string, value: IVehicle | any }) {
  }
}

export class LoadTestDriveVinVehicle implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_TESTDRIVE_VIN_VEHICLE;

  constructor(public payload: { dealerCode: string, vehicle: IVehicle }) {
  }
}
export class LoadTestDriveVinVehiclecSuccess implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_TESTDRIVE_VIN_VEHICLE_SUCCESS;

  constructor(public payload: { data: any }) {
  }
}
export class LoadTestDriveVinVehiclecFailure implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_TESTDRIVE_VIN_VEHICLE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class LoadAllChecklist implements Action {
  readonly type: string = SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST;

  constructor() {}
}

export class LoadAllChecklistSuccess implements Action {
  readonly type = SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST_SUCCESS;

  constructor(public payload: { checklistDetails: IChecklist[] }) {
  }
}

export class LoadAllChecklistFailure implements Action {
  readonly type = SalesAppointmentActionTypes.LOAD_ALL_CHECKLIST_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class CreateChecklist implements Action {
  readonly type: string = SalesAppointmentActionTypes.CREATE_CHECKLIST;

  constructor(public payload: { data: any }) {
  }
}
export class UpdateCurrentStep implements Action {
  readonly type: string = SalesAppointmentActionTypes.UPDATE_CURRENT_STEP;

  constructor(public payload: { step: number }) {
  }
}

export type SalesAppointmentType =
  UpdateSalesFormData
  | CreateSalesAppointment
  | UpdatePatchSalesForm
  | CreateSalesAppointmentSuccess
  | CreateSalesAppointmentFail
  | GetEditData
  | GetEditDataSuccess
  | GetEditDataFail
  | EditSalesAppointment
  | EditSalesAppointmentSuccess
  | EditSalesAppointmentFail
  | LoadAvailabilityOptions
  | LoadAvailabilityOptionsSuccess
  | LoadAvailabilityOptionsFail
  | LoadSeriesAndTrim
  | LoadSeriesAndTrimSuccess
  | LoadSeriesAndTrimFail
  | LoadInventoryVehicle
  | LoadInventoryVehicleSuccess
  | LoadInventoryVehicleFail
  | LoadExteriorColors
  | LoadExteriorColorsSuccess
  | LoadExteriorColorsFail
  | ResetSalesAppointmentState
  | ResetSalesAppointmentForm
  | ResetExteriorColors
  | LoadCustomerDetails
  | LoadAllChecklist
  | LoadAllChecklistSuccess
  | LoadAllChecklistFailure
  | CreateChecklist
  | UpdateCurrentStep
