import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DealerAdminService } from '../../shared/services/asp/dealer-admin.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private readonly authService: AuthService, private readonly dealerAdminService: DealerAdminService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.user.pipe(
            switchMap(user => {
                if (user && !!this.authService.getToken()) {
                    req = req.clone({
                        setHeaders: {
                            Authorization: `Bearer ${this.authService.getToken()}`
                        }
                    });
                }
                const consumerKey = this.dealerAdminService.getApiKey('consumer')
                if(!req.url.includes('fetchapikey') && consumerKey){
                    req = req.clone({
                        setHeaders: {
                            'X-API-KEY': consumerKey
                        }
                    });
                }
                return next.handle(req);
            })
        );
    }
}
