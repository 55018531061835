import { IAvailabilityForm } from './../store/service-appointment/service-appointment.reducer';
import { AnalyticsService } from './../shared/services/analytics.service';
import { AuthService } from './../shared/services/auth.service';
import { AppointmentFormService } from './appointment-form.service';
import {
  AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AspBannerService } from '../shared/components/asp-banner/asp-banner.service';
import { UserVehicleService } from '../shared/services/user-vehicle.service';
import { AppointmentService } from '../shared/services/asp/appointment.service';
import { SelectVehicleComponent } from './select-vehicle/select-vehicle.component';
import { SelectServiceComponent } from './select-service/select-service.component';
import { AppointmentTimeComponent } from './appointment-time/appointment-time.component';
import { YourInformationComponent } from './your-information/your-information.component';
import { Store } from '@ngrx/store';
import { IFooterSummary, IServiceAppointmentForm, ServiceAppointmentState } from '../store/service-appointment/service-appointment.reducer';
import { forkJoin, iif, Observable } from 'rxjs';
import { Actions } from '@ngrx/effects';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SubscriptionList } from '../shared/models/asp.types';
import * as util from '../shared/services/util.service';
import { DashboardState } from '../store/dashboard/dashboard.reducer';
import * as fromActions from '../store/service-appointment/service-appointment.actions';
import * as fromSelectors from '../store/service-appointment/service-appointment.selectors';
import * as dashboardActions from '../store/dashboard/dashboard.actions';
import * as fromDashboard from '../store/dashboard/dashboard.selectors';
import { AppointmentType, IDateAvailability, IServiceAppointment, IServiceOpcode, IGeneralSettingsAppointmentRequest } from '@signal/asp-data-commons';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { GLOBAL_CONSTANT } from '../shared/services/util.service';
import uuidv4 from 'uuidv4';
import { AlertService } from '../shared/services/alert.service';
import { DealerState } from '../store/dealer/dealer.reducer';
import * as fromDealerSelector from '../store/dealer/dealer.selectors';

@Component({
  selector: 'app-service-appointment',
  templateUrl: './service-appointment.component.html',
  styleUrls: ['./service-appointment.component.scss'],
})
export class ServiceAppointmentComponent implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy {
  isEditMode = false;
  isSubmitting = false;
  selectedIndex = 0;
  continueBtnText: string[] = ['selectService.continue', 'selectService.continue', 'selectService.continue', 'appointmentInformation.submit', 'appointmentInformation.submitting'];
  disclaimerToggle = false;
  isEditMode$: Observable<boolean>;
  serviceAppointmentFooterSummaries$: Observable<IFooterSummary[]>;
  toShowFooterAppointment: boolean = true;
  breadcrumbSteps: { name: string, id: number }[] = [
    { name: 'selectService.vehicle', id: 0 },
    { name: 'selectService.services', id: 1 },
    { name: 'selectService.appointment', id: 2 },
    { name: 'selectService.confirm', id: 3 }
  ];
  @ViewChild(SelectVehicleComponent, { static: false }) selectVehicleComponent: SelectVehicleComponent;
  @ViewChild(SelectServiceComponent, { static: false }) selectServiceComponent: SelectServiceComponent;
  @ViewChild(AppointmentTimeComponent, { static: false }) appointmentTimeComponent: AppointmentTimeComponent;
  @ViewChild(YourInformationComponent, { static: false }) yourInformationComponent: YourInformationComponent;
  vin: string = '';
  private signedIn = false;
  private signedInUserEmail;
  appointmentId: string;
  private subs: SubscriptionList = {};
  private editDetails: { appointmentId: string };
  private lastOpcodeSelection: string[];
  private readonly analyticsSessionId: string;
  private profile: any;
  private roundedOffMileage: number;  
  settings:IGeneralSettingsAppointmentRequest={} as IGeneralSettingsAppointmentRequest;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly router: Router,
    private readonly bannerService: AspBannerService,
    private readonly appointmentService: AppointmentService,
    public readonly appointmentFormService: AppointmentFormService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userVehicleService: UserVehicleService,
    private readonly dashboardState: Store<DashboardState>,
    private readonly serviceAppointmentState: Store<ServiceAppointmentState>,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly alertService: AlertService,
    private readonly analyticsService: AnalyticsService,
    private readonly dealerState: Store<DealerState>
  ) {
    this.authService.user.subscribe(user => {
      this.signedIn = user && !this.authService.isExpired();
      if (this.signedIn) {
        this.signedInUserEmail = user?.email;
        this.profile = user;
        let payload: { queryText: string };
        payload = {
          queryText: user.email,
        };
        this.dashboardState.dispatch(new dashboardActions.LoadAppointments(payload));
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.editDetails = { appointmentId: params.id };
      this.isEditMode = !!this.editDetails.appointmentId;
      if (this.isEditMode) {
        /* Setting the edit mode actions in async stack, to allow store to be ready first */
        setTimeout(() => this.setUpStoreForEdit(), 0);
      }
    });

    this.serviceAppointmentState.select(fromSelectors.selectServiceOpcodesRecommendedMileage).pipe(take(1)).subscribe(roundedOff => {
      this.roundedOffMileage = roundedOff;
    });
    this.analyticsSessionId = uuidv4();
  }

  ngOnInit(): void {
    this.setupStore();
  }

  ngAfterViewInit() {
    this.bannerService.show();
  }

  getDealerDetails() {
    return this.appointmentFormService.dealerDetails;
  }

  switchTabFromBreadcrumbs(tabIndex) {
    const tabTrackingData = {
      content_section: 'appointment tab',
      link_module: 'nav',
      link_text: 'navigation breadcrumbs',
      link_input_field: this.breadcrumbSteps[tabIndex].name
    }
    this.switchTab(tabIndex);
    this.analyticsService.trackLink(tabTrackingData, true);
  }

  onConfirmButtonClick() {
    const tabTrackingData = {
      content_section: this.breadcrumbSteps[this.selectedIndex].name + 'tab',
      link_module: 'nav',
      link_text: 'confirm button',
    }
    this.switchTab()
    this.analyticsService.trackLink(tabTrackingData, true);
    if (this.selectedIndex === 0) {
      this.serviceAppointmentState.dispatch(new fromActions.TriggerLoader());
    }
  }

  switchTab(tabIndex?) {
    // TODO fix for showing mandatory fields as errors
    // this.appointmentFormService.appointmentMainForm.updateValueAndValidity();
    // fire page loads
    /*Analytics Section Begins*/
    switch (this.selectedIndex) {
      case 0:
        this.serviceAppointmentState.select(fromSelectors.selectVehicleForm).pipe(take(1)).subscribe(vehicle => {
          const vehicleTrackingData: any = {
            servscheduler_sessionid: this.analyticsSessionId,
            content_section: 'Schedule Service Appointment',
            link_text: 'Vehicle Selection'
          };
          this.analyticsService.serviceSchedulerVehicleData = {
            ...this.analyticsService.serviceSchedulerVehicleData,
            make: vehicle.make,
            year: vehicle.year,
            model: vehicle.model,
            mileage: vehicle.mileage,
            vin: vehicle.vin
          };
          // this.analyticsService.trackLink(vehicleTrackingData,true);
        });
        break;
      case 1:
        this.serviceAppointmentState.select(fromSelectors.selectServiceForm).pipe(take(1)).subscribe(services => {
          const selectedServices = {
            opCodes: '',
            msrp: 0,
            titles: ''
          };
          services.selectedServices.forEach((service, index) => {
            if (index !== (services.selectedServices.length - 1)) {
              selectedServices['opCodes'] = selectedServices['opCodes'] + service.opcode + ' , ';
              selectedServices['titles'] = selectedServices['titles'] + (service.details.displayName ?? service.details.description) + ',';
            }
            else {
              selectedServices['opCodes'] = selectedServices['opCodes'] + service.opcode;
              selectedServices['titles'] = selectedServices['titles'] + (service.details.displayName ?? service.details.description);
            }
            selectedServices['msrp'] = selectedServices['msrp'] + service?.details?.pricing?.discount?.price
          })
          this.analyticsService.serviceSchedulerToolData = {
            ...this.analyticsService.serviceSchedulerToolData,
            code: selectedServices['opCodes'],
            msrp: selectedServices['msrp'],
            title: selectedServices['titles']
          }
          const serviceTrackingData: any = {
            servscheduler_sessionid: this.analyticsSessionId,
            content_section: 'Schedule Service Appointment',
            link_text: 'Service Selection',
            servscheduler_services_count: services.selectedServices.length
          };

          // this.analyticsService.trackLink(serviceTrackingData,true);
          const allAddons = this.getAllAddonOpCodes();
          const selectedAddons = services.selectedServices.map(t => t.opcode);
          const upsellTrackingData: any = {
            servscheduler_sessionid: this.analyticsSessionId,
            content_section: 'Schedule Service Appointment',
            link_text: 'Upsell Opcode Selection',
            servscheduler_upsell_services_selected: allAddons.some(r => selectedAddons.indexOf(r) >= 0)
          };
          // this.analyticsService.trackLink(upsellTrackingData,true);

        });
        this.serviceAppointmentState.select(fromSelectors.selectVehicleForm).pipe(take(1)).subscribe(vehicle => {
          const mileageTrackingData: any = {
            servscheduler_sessionid: this.analyticsSessionId,
            content_section: 'Schedule Service Appointment',
            link_text: 'Recommended Mileage Selection',
            servscheduler_manual_mileage_selected: this.roundedOffMileage !== this.appointmentFormService.selectedMileage
          };
          // this.analyticsService.trackLink(mileageTrackingData,true);
        });

        break;
      case 2:
      case 3:
      default:
        break;
    }

    /*Analytics Section Ends*/
    if (this.toShowFooterAppointment) {
      if (tabIndex !== undefined && tabIndex >= 0) {
        setTimeout(() => {
          this.setSelectedIndex(tabIndex);
        });
      } else {
        if (!this.isValidForm()) {
          this.markAsTouched();
          return false;
        }
        setTimeout(() => {
          this.setSelectedIndex(this.selectedIndex + 1);
          if (this.selectedIndex === 4) {
            this.submitTheAppointment();
          }
        });
      }
    }
  }

  setSelectedIndex(index: number) {
    const prevIndex = this.selectedIndex;
    this.selectedIndex = index;
    this.serviceAppointmentState.dispatch(new fromActions.UpdateCurrentStep({ step: index }));

    if (prevIndex < index && index === 1) {
      this.serviceAppointmentState.dispatch(new fromActions.TriggerLoaderFail());
    }
    if (index === 3) {
      this.bannerService.hideCarImage();
    } else if (index === 2) {
      this.checkAndUpdateAvailability();
      this.bannerService.showCarImage();
    } else {
      this.bannerService.showCarImage();
    }
  }

  private checkAndUpdateAvailability() {
    this.subs.appointmentFormSub = this.serviceAppointmentState.select(fromSelectors.selectAppointmentForm)
      .pipe(take(1))
      .subscribe((appointment: IServiceAppointmentForm) => {
        /* Need to fetch availability only when the opcode selection has changed */
        const currentOpcodeSelection = appointment.serviceForm?.selectedServices?.map(ss => ss.opcode);
        this.vin = appointment.vehicleForm.vin ? appointment.vehicleForm.vin : '';
        this.appointmentId = appointment.appointmentId ? appointment.appointmentId : '';
        if (_.isEqual(this.lastOpcodeSelection, currentOpcodeSelection)) {
          return;
        }
        this.lastOpcodeSelection = currentOpcodeSelection;

        /* If so, recalculate the availability */
        const chosenDate = appointment.availabilityForm.availability.appointmentStartDate;
        const transport = appointment.availabilityForm.transport;
        const advisorId = appointment.availabilityForm.availability.advisorId || '-1';
        if (!!chosenDate) {
          const date = DateTime.fromISO(chosenDate)
            .startOf('month')
            .toFormat('yyyy-MM-dd');
          this.serviceAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date, advisorId, transport, vin: this.vin, appointmentId: this.appointmentId }));
        } else {
          this.serviceAppointmentState.dispatch(new fromActions.LoadAvailability(
            { date: "", advisorId, transport, vin: this.vin, appointmentId: this.appointmentId }
          ));
        }
      });
  }

  gotoBack() {
    const availabilityTrackingData: any = {
      servscheduler_sessionid: this.analyticsSessionId,
      content_section: 'Schedule Service Appointment',
      link_text: 'back button clicked',
    };
    this.analyticsService.trackLink(availabilityTrackingData, true);
    this.setSelectedIndex(this.selectedIndex - 1);
  }

  firePageLoadTags(tabIndex) {
    switch (tabIndex) {
      case 0:
        const vehiclePageLoadTracker = {
          content_section: 'vehicle tab'
        }
        this.analyticsService.trackPageLoad(vehiclePageLoadTracker, true);
        break;
      case 1:
        const servicePageLoadTracker = {
          content_section: 'services tab'
        }
        this.analyticsService.customTrackLink('km-service-schedule-shown', servicePageLoadTracker, true);
        break;
      case 2:
        const appointmentPageLoadTracker = {
          content_section: 'appointment tab'
        }
        this.analyticsService.trackPageLoad(appointmentPageLoadTracker, true);
        break;
      case 3:
        const confirmPageLoadTracker = {
          content_section: 'confirm tab'
        }
        this.analyticsService.trackPageLoad(confirmPageLoadTracker, true);
        break;
      default:
        break;
    }
  }

  onConfirmation(t: any) {
    const pageloadTrackingData = {
      content_section: "service is scheduled"
    }
    this.analyticsService.customTrackLink("km-service-scheduled", pageloadTrackingData, true);
    this.dashboardState.dispatch(new dashboardActions.UpsertAppointment({ appointment: t.data }));
    const urlState = {...t.data};   
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: t.data.appointmentId,
      },
      state: urlState
    };

    util.navigateToRoutePromise(util.getRouteFromWindow('service') ? `${util.getRouteFromWindow('service')}/confirm` : '/service/confirm', this.router, navigationExtras);
  }

  isValidForm() {
    if (this.selectedIndex === 0) {
      return this.selectVehicleComponent?.step?.valid;
    } else if (this.selectedIndex === 1) {
      return this.selectServiceComponent?.step?.valid;
    } else if (this.selectedIndex === 2) {
      return this.appointmentTimeComponent?.step?.valid;
    } else if (this.selectedIndex === 3) {
      return this.yourInformationComponent?.step?.valid;
    }
  }

  markAsTouched() {
    this.appointmentFormService.markStepAsTouched(this.selectedIndex);
  }

  checkNav(tabNo: number) {
    switch (tabNo) {
      case 0:
        return true;
      case 1:
        return this.areStepsValid(0);
      case 2:
        return this.areStepsValid(0, 1);
      case 3:
        return this.areStepsValid(0, 1, 2);
      default:
        return false;
    }
  }

  areStepsValid(...steps: number[]) {
    for (const step of steps) {
      if (!this.isStepValid(step)) {
        return false;
      }
    }
    return true;
  }

  isStepValid(step: number) {
    switch (step) {
      case 0:
        return this.selectVehicleComponent?.step.valid;
      case 1:
        return this.selectServiceComponent?.step.valid;
      case 2:
        return this.appointmentTimeComponent?.step.valid;
      case 3:
        return this.yourInformationComponent?.step.valid;
      default:
        return false;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    util.unsubscribeSubscriptions(this.subs);
    this.bannerService.update('');
    this.serviceAppointmentState.dispatch(new fromActions.ResetServiceAppointmentState());
  }

  private submitTheAppointment() {
    this.trackSubmit();
    this.isSubmitting = true;

    const serviceAppointmentState$ = this.serviceAppointmentState.select(fromSelectors.selectServiceAppointmentFeature);

    /* Create appointment observable */
    const createAppointment$ = serviceAppointmentState$.pipe(
      switchMap((serviceAppointmentFeature: ServiceAppointmentState) =>
      {
        const payload={...this.appointmentFormService.getMainFormDataAsCreateReq(serviceAppointmentFeature, this.profile),settings:this.settings}
        return this.appointmentService.create(payload);
      }
      ));
      
      /* Update appointment observable */
      const updateAppointment$ = serviceAppointmentState$.pipe(
        switchMap((serviceAppointmentFeature: ServiceAppointmentState) =>
        {
          const payload={...this.appointmentFormService.getMainFormDataAsUpdateReq(serviceAppointmentFeature, this.profile),settings:this.settings}
          return this.appointmentService.update(payload);
        }
      ));
        
    this.subs.appointmentCreateSub =
      this.isEditMode$.pipe(
        mergeMap((isEditMode: boolean) =>
          iif(() => !isEditMode,
            createAppointment$,
            updateAppointment$
          ))).subscribe(t => {
            this.onConfirmation(t);
            this.isSubmitting = false;
          }, error => {
            console.log(error);
            let errorMsg = GLOBAL_CONSTANT.errorMessage;
            if (error?.error?.status?.messages.length > 0) {
              errorMsg = error.error.status.messages[0];
            }
            this.alertService.openDialog(errorMsg)
            this.isSubmitting = false;
            this.selectedIndex--;
          });
  }

  private trackSubmit() {
    forkJoin([
      this.serviceAppointmentState.select(fromSelectors.selectAvailability).pipe(take(1)),
      this.serviceAppointmentState.select(fromSelectors.selectAvailabilityForm).pipe(take(1))
    ]).pipe(take(1)).subscribe(([availability, availabilityForm]) => {
      const availabilityTrackingData: any = {
        servscheduler_sessionid: this.analyticsSessionId,
        content_section: 'Schedule Service Appointment',
        link_text: 'Availability Options',
        servscheduler_advisor_preference: availabilityForm.availability.advisorId !== '-1',
        servscheduler_language_preference: availabilityForm.availability.languageId !== '-1',
        servscheduler_is_suggested_timeslot: this.isSuggestedTimeslot(availability.data.suggestedTimes, availabilityForm)
      };
    });
  }

  private isSuggestedTimeslot(suggestedTimes: IDateAvailability[], availabilityForm: IAvailabilityForm) {
    const availability = availabilityForm.availability;
    return suggestedTimes.some(t =>
      t.date === availability.appointmentStartDate &&
      t.timeSlots.some(r => r.timeSlotId.indexOf(availability.timeSlotId)));
  }

  private setupStore() {
    this.serviceAppointmentState.dispatch(new fromActions.LoadAvailableMileages());
    this.serviceAppointmentState.dispatch(new fromActions.LoadAvailabilityOptions());

    this.isEditMode$ = this.serviceAppointmentState.select(fromSelectors.selectIsEditMode);
    this.serviceAppointmentFooterSummaries$ = this.serviceAppointmentState.select(fromSelectors.selectServiceAppointmentFooterSummaries);

    this.subs.currentStepSub = this.serviceAppointmentState.select(fromSelectors.selectCurrentStep)
      .subscribe((currentStep: number) => {
        this.selectedIndex = currentStep;
        this.firePageLoadTags(this.selectedIndex);
      });
    this.dealerState.select(fromDealerSelector.dealerDetails).subscribe((data: any) => {
      if (data && Object.keys(data).length) {
        this.settings.dealerInfo = data.data;
      }
    });
    this.dealerState.select(fromDealerSelector.serviceSettings).subscribe((data: any) => {
      if (data && Object.keys(data).length) {
        this.settings.portal = data.portal;
        this.settings.time = data.time;
        this.settings.isAspCrmIntegrationEnabled = data.isAspCrmIntegrationEnabled;
        this.settings.isAspDmsIntegrationEnabled = data.isAspDmsIntegrationEnabled;
        this.settings.isConsumerPortalEnabled = data.isConsumerPortalEnabled;
        this.settings.isServiceAccessible = data.isServiceAccessible;
      }
    });
  }

  private setUpStoreForEdit() {
    const props = { appointmentId: this.editDetails.appointmentId };
    const selectAppointmentById$ = this.serviceAppointmentState.select(fromDashboard.selectAppointmentById, props);
    const fetchAppointmentFromBackend$ = this.appointmentService
      .get(AppointmentType.SERVICE, this.editDetails.appointmentId)
      .pipe(map(r => r.data));

    this.subs.appointmentLoadSub = this.dashboardState.select(fromDashboard.selectAppointmentsLoadedById, props).pipe(
      mergeMap((appointmentsLoaded: boolean) =>
        iif(() => appointmentsLoaded,
          selectAppointmentById$,
          fetchAppointmentFromBackend$
        ))).subscribe((appointment: IServiceAppointment) => {
          const date = DateTime.fromISO(appointment.appointmentStartDate)
            .startOf('month')
            .toFormat('yyyy-MM-dd');

          this.serviceAppointmentState.dispatch(
            new fromActions.EditServiceAppointment({ ...this.editDetails, email: appointment.emailAddress, appointment }));
        }
        );
  }

  get getDisclaimerText() {
    return GLOBAL_CONSTANT.schedulingDisclaimerText;
  }

  toShowFooterDetails(event) {
    this.toShowFooterAppointment = event;
  }

  getAllAddonOpCodes() {
    const addonOpCodes = [];
    this.serviceAppointmentState.select(fromSelectors.selectRecommendedServiceOpcodes).pipe(take(1)).subscribe(recommendedServices => {
      this.getAddons(recommendedServices, addonOpCodes);
    });
    this.serviceAppointmentState.select(fromSelectors.selectOtherServiceOpcodes).pipe(take(1)).subscribe(otherServices => {
      this.getAddons(otherServices, addonOpCodes);
    });
    return addonOpCodes;
  }

  getAddons(services: IServiceOpcode[], addonOpCodes: string[]) {
    services.forEach(service => {
      if (service?.addOnOpcodes?.opcodes) {
        addonOpCodes.push(...(service.addOnOpcodes.opcodes.map(t => t.opcode)));
      }
    });
  }

}
